import React from "react"

import Container from "./container"
import Social from "../other/social";

import {
  FaCheckCircle,
} from "react-icons/all";
import MicroSure from "../elements/micro-sure";

const Footer = () => (
  <>
    <Container id="footer-main-wrap">
      <div className="left">
        <div className="item">
          <h4>microSURE</h4>
          <h5>
            <span><FaCheckCircle /></span> Safe Antimicrobial Solutions<br />
            <span><FaCheckCircle /></span> Better Protection Products<br />
            <span><FaCheckCircle /></span> Long-Lasting Protection
          </h5>
        </div>
        <div className="item">
          <h4>FFF Enterprises</h4>
          <h5>
            <span><FaCheckCircle /></span> Critical-Care Supplier<br />
            <span><FaCheckCircle /></span> Trusted Distributor<br />
            <span><FaCheckCircle /></span> Leading With Innovation
          </h5>
        </div>
      </div>
      <div className="right">
        <Social />
        <a className="button" href="mailto:microsure@fffenterprises.com">Contact Us</a>
      </div>
    </Container>
    <Container id="footer-copyrights-wrap">
      &copy; { (new Date().getFullYear()) } <MicroSure />
    </Container>
  </>
)

export default Footer
