import React from "react"
import { Link } from "gatsby"

const Menu = () => (
  <div id="nav-main-wrap">
    <nav id="nav-main">
      <ul className="menu">
        <li><Link activeClassName="active" to="/products">Products</Link></li>
        <li><Link activeClassName="active" to="/technology">Technology</Link></li>
        <li><Link activeClassName="active" to="/industry">Industry</Link></li>
        <li><Link activeClassName="active" to="/how-to">How-To</Link></li>
        <li><Link activeClassName="active" to="/our-story">Our Story</Link></li>
      </ul>
    </nav>
  </div>
)

export default Menu
