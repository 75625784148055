import React from "react"

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/all";

import {
  useStaticQuery,
  graphql
} from "gatsby"

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          facebook
          twitter
          linkedin
          instagram
          youtube
        }
      }
    }
  `);

  return (
    <>
      <a className="social-icon" rel="noopener noreferrer" target="_blank" href={data.site.siteMetadata.facebook}><FaFacebookF /></a>
      <a className="social-icon" rel="noopener noreferrer" target="_blank" href={data.site.siteMetadata.instagram}><FaInstagram /></a>
      <a className="social-icon" rel="noopener noreferrer" target="_blank" href={data.site.siteMetadata.twitter}><FaTwitter /></a>
      <a className="social-icon" rel="noopener noreferrer" target="_blank" href={data.site.siteMetadata.linkedin}><FaLinkedin /></a>
      <a className="social-icon" rel="noopener noreferrer" target="_blank" href={data.site.siteMetadata.youtube}><FaYoutube /></a>
    </>
  )
}

export default Social
