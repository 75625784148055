import React from "react"
import Container from "./container";
import Menu from "./menu";
import {Link} from "gatsby";

import {
  FaPhone,
  FaUser,
  FaSearch,
} from "react-icons/all";

const Header = () => (
  <>
    <Container id="header-main">
      <Link id="logo" to="/">microSURE</Link>
      <Menu />
      <div id="header-actions">
        <a href="tel:+18008437477"><FaPhone /></a>
        <a href="https://fffenterprises.com/"><FaUser /></a>
        <a className="default"><FaSearch /></a>
      </div>
    </Container>
  </>
)

export default Header
